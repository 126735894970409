// LoggedOutDisplay.tsx
import React from "react";
import raintrainGif from "../../resources/rainingtrain.gif"; // Adjust the path according to your project structure

const LoggedOutDisplay: React.FC = () => {
    return (
        <div className="logged-out-container">
            <img src={raintrainGif} alt="Rain Train" />
        </div>
    );
};

export default LoggedOutDisplay;
