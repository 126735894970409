import React from "react";
import { Accordion } from "react-bootstrap";
import "./PluginList.css";

const pluginDescriptions = [
    {
        header: "Agility",
        description: {
            about: "Runs on top of rooftops around Gielinor for that sweet Agility exp.",
            feature: "- Progressive Agility\n- Webwalker Support\n- Picks up Marks of Grace",
        },
    },
    {
        header: "Auto Eater",
        description: {
            about: "Automatically consumes food, pots, and items for all your PvM or PvE needs.",
            feature: "- All Food\n- All Pots\n- Imbued/Saturated Heart",
        },
    },
    {
        header: "Bankskiller",
        description: {
            about: "This Bankskiller has an insane amount of presets that you can use, if that doesn't satisfy you, it also offers a fully customizable item-on-item or item-on-object menu as well.",
            feature:
                "- Herb Cleaning\n- Potion Making\n- Superglass Make\n- Plank Make\n- Gem Cutting\n- D'Hide Body Crafting\n- Jewellery Enchanting\n- Humidify Items\n- Superheat Ore\n- Furnace Smelting/Crafting\n- Bolt Enchanting\n- Tan Leather\n- Spin Flax\n- Jugs of Wine\n- 0T Serum 207\n- 1T Hosidius Karambwan Cooking\n- Custom Item on Item\n- Custom Item on Object",
        },
    },
    {
        header: "Blast Furnace",
        description: {
            about: "Blast Furnace plugin that grabs ores, dumps them, and returns with bars! Full automation and redirected one-click supported!",
            feature:
                "- All bars supported\n- Coal Bag support\n- Glove switching\n- Stamina Potion support\n- Click Redirection (One-Click Mode)",
        },
    },
    {
        header: "Blue Dragons",
        description: {
            about: "Ironmen rejoice, this plugin claps Blue Dragons in Taverley dungeon and loots their bones and dragonhide for that dank early game GP, Prayer, and Crafting EXP. Banks at Falador.",
            feature: "- Safespot Option\n- Option to loot Dragonhide\n- Custom Loot IDs\n- Eats Food",
        },
    },
    {
        header: "Break Handler",
        description: {
            about: "Sets breaks for your plugins!",
            feature:
                "- Save your account details\n- Custom run times\n- Custom break times\n- World hop & region selection\n- Stop after X breaks",
        },
    },
    {
        header: "Chaos Bones",
        description: {
            about: "Gets 99 Prayer at the Chaos Altar. Use with Auto Hop for best results!",
            feature: "- Custom Bones\n- Auto Hop Compatibility\n- Click Redirection (One-Click Mode)\n- Custom Actions Per Tick",
        },
    },
    {
        header: "Construction",
        description: {
            about: "1-99 Construction made quick and easy.",
            feature:
                "- Chairs, Bookcases, Larders, and Tables supported\n- Auto Pay/Moneybag support\n- Butler/Demon/Phials support\n- Click Redirection (One-Click Mode)",
        },
    },
    {
        header: "Farm Run",
        description: {
            about: "Automate your farm runs and chain it with birdhouse and seaweed runs!",
            feature:
                "- Supports all Herb/Tree/Fruit Tree/Flower/Allotment/Bush patches\n- Dynamically paths around via the webwalker\n- Tree Protection support\n- Leprechaun support\n- Refills compost bin with allotment produce\n- Compost Potion and Volcanic Ash support\n- Stamina Potion support\n- Fruit/Bush picking support\n- Break Handler support",
        },
    },
    {
        header: "Fossil Island",
        description: {
            about: "Automates both your birdhouse and seaweed runs!",
            feature:
                "- Replaces completed birdhouses with new ones\n- All birdhouses and seeds supported\n- Plants, harvests, and composts seaweed underwater\n- Birdhouse and seaweed runs can be chained\n- This plugin can be chained with Farm Run\n- Break Handler support",
        },
    },
    {
        header: "GOTR",
        description: {
            about: "Plays the Guardians of the Rift minigame for you",
            feature:
                "- Does GOTR for you\n- Mines fragments at the start of the game\n- Set your own preparation time\n- Crafts essence for you\n- Uses portals efficiently and mines the big essence rock inside the portal\n- Manually toggle runes you don't want to make\n- Checks for RC level and Troll Stronghold / Sins of The Father\n- Enters and crafts runes at the altar for you\n- Deposits runes and powers up the guardian\n- Dynamically resets states, able to continue on after the game ends",
        },
    },
    {
        header: "Herbiboar",
        description: {
            about: "Hunts for Herbi!",
            feature:
                "- One Click and Full Automation modes\n- Banking at Fossil Island camp\n- Stamina Potion support\n- Uses Herb Sack\n- Webwalking support",
        },
    },
    {
        header: "Hunter",
        description: {
            about: "Hunts your way around Gielinor!",
            feature:
                "- One Click and Full Automation modes\n- Bird Trapping\n- Box Trapping\n- Salamanders\n- Falconry (start with the falcon glove)",
        },
    },
    {
        header: "Hotkeys",
        description: {
            about: "Various hotkeys to enhance your game!",
            feature:
                "- 1T Prayer Flicking\n- Protection Prayers\n- Left Click Casting\n- Custom Gear Sets\n- Custom Offensive Prayers\n- Custom Tick/Eating",
        },
    },
    {
        header: "Kourend Favour",
        description: {
            about: "Hate gaining favour yourself? Do yourself a favour and use Shismo Kourend Favour.",
            feature:
                "- Automatically grabs favour for you at the locations below\n- Shayzien Medkits Healing\n- Shayzien Soldiers Fighting\n- Hosidius Saltpetre Mining\n- Hosidius Ploughing\n- Lovakengj Sulphur Mining\n- Piscarilius Fish Gathering",
        },
    },
    {
        header: "MM2 Caves",
        description: {
            about: "Stacks and whacks monkeys with spells or chins",
            feature:
                "- Stacks monkeys for you\n- Automatically resets aggro\n- Helps you hop worlds when you search the hole and find a player\n- Picks up prayer pots and drinks them\n- Stamina potion support\n- Emergency house panic tab support",
        },
    },
    {
        header: "Motherlode Mine",
        description: {
            about: "'I am a dwarf and I'm digging a hole\nDiggy, diggy hole, digging a hole'",
            feature:
                "- Upper and lower floor support\n- Multiple area support\n- Fills sack then withdraws\n- Fixes water wheel if necessary",
        },
    },
    {
        header: "MTA",
        description: {
            about: "Automatically completes MTA",
            feature:
                "- Supports all rooms (will do whatever room is chosen)\n- Supports level 1-6 enchantments\n- Choose between bones to bananas / bones to peaches\n- Choose between low alchemy / high alchemy",
        },
    },
    {
        header: "Mahogany Homes",
        description: {
            about: "'Aaah, nothing like a nice cuppa tea!'",
            feature: "- Completes Mahogany Home contracts\n- Drinks tea\n- Grabs new contracts\n- Banks at Falador",
        },
    },
    {
        header: "PvM Assist",
        description: {
            about: "Automate your farm runs and chain it with birdhouse and seaweed runs!",
            feature:
                "Supports all Herb/Tree/Fruit Tree/Flower/Allotment/Bush patches\nDynamically paths around via the webwalker\nTree Protection support\nLeprechaun support\nRefills compost bin with allotment produce\nCompost Potion and Volcanic Ash support\nStamina Potion support\nFruit/Bush picking support\nBreak Handler support",
        },
    },
    {
        header: "NMZ",
        description: {
            about: "AFK to 99 combat",
            feature:
                "- Supports Locator Orb & Rock Cake\n- Supports Overloads & Absorptions\n- Supports all powerups (will add spec/weapon swaps next)\n- Restocks potions after each run\n- Rebuys Overloads & Absorptions from the reward chest\n- Supports bank pin plugin",
        },
    },
    {
        header: "Pyramid Plunder",
        description: {
            about: "Plunder some tombs for free sceptres!",
            feature:
                "- Completes the Pyramid Plunder minigame\n- Chest only sceptre hunting\n- Sarcophagus/urn looting\n- Recharges Sceptre with gold trinkets\n- Banks at altar bank",
        },
    },
    {
        header: "Rogue's Den",
        description: {
            about: "'Your rogue clothing allows you to steal twice as much loot!'",
            feature: "- Completes the rogues den minigame\n- Stamina Pot (1) support\n- Banks crates",
        },
    },
    {
        header: "Runecrafting",
        description: {
            about: "Craft runes all around the world!",
            feature:
                "- ZMI, Morytania Bloods, Combo Lavas, Fire and Astral Runes supported\n- Pure and Daeyalt essence supported\n- Pouches/Repair (NPC Contact) supported\n- Binding Necklaces for combo runes\n- Stamina Potions for long routes\n- Dynamically paths using Shismo Webwalker\n- Click Redirection (One-Click Mode)",
        },
    },
    {
        header: "Secondaries",
        description: {
            about: "Gathers Herblore secondaries for you!",
            feature:
                "- Red Spiders Eggs (Tower Of Life)\n- Wine Of Zamorak (Unfinished Webwalking)\n- Blue Dragon Scales (Skiller)\n- Volcanic Ash (Skiller)\n- Eyes Of Newt (Shopper)\n- Chocolate Dust (Shopper)\n- Mort Myre Fungi (Fungus Collector)\n- Limpwurt Roots (Farm Run 2)\n- Whiteberries (Farm Run 2)\n- Snape Grass (Farm Run 2)",
        },
    },
    {
        header: "Shopper",
        description: {
            about: "Hops and Shops for your wanted items or sells your unwanted items.",
            feature:
                "- Choose whether the shop is an object or NPC\n- Custom Shop ID\n- Bank Type (booth or chest)\n- Custom Bank ID\n- Set a Designated world point near your shop of choice and bank of choice",
        },
    },
    {
        header: "Skiller",
        description: {
            about: "Custom AIO skiller that is extremely versatile, customize to your liking!",
            feature:
                "- NPC support\n- Object support\n- Ground Item support\n- Set a distance from player or anchored worldpoint\n- Webwalking support for banks and traveling back\n- Custom item dropping\n- Custom banking support\n- Reaggro support\n- One click or fully automated, your choice\n- Various overlays to assist with valid objects / range",
        },
    },
    {
        header: "Sq'irker",
        description: {
            about: "Go out sq'irkin with the boys in W523",
            feature:
                "- Mass World 523\n- Collects Summer Sq'irks\n- Banks at Al-Kharid\n- Stamina support\n- Click Redirection (One-Click Mode)",
        },
    },
    {
        header: "Sulliusceps",
        description: {
            about: "Sulliusceps are a cool 100-120k exp/hr for WC.",
            feature:
                "- Chops Sulliusceps for you\n- Refills mushroom pit\n- Banks Sulliuscep caps and Fossils\n- Intelligently detects and paths to correct Sulliuscep\n- Will avoid jumping if under 50 hp\n- Banks at Edgeville or Ferox\n- Pool restoration support\n- Can use con cape/house tabs or runes\n- Has group house tele support\n- Custom antipoison support\n- Custom food support",
        },
    },
    {
        header: "Thiever",
        description: {
            about: "Cor blimey mate, what are ye doing in me pockets?",
            feature:
                "- Blackjacking and Custom NPC/Stalls.\n- Automatically resets Blackjack NPC\n- Setup an 'anchor' to only thieve where you want to thieve.\n- Banking/Banking on full and teleporting to Bank\n- Custom Food\n- Ability to drop unwanted items\n- Dodgy Necklace and Shadow Veil support",
        },
    },
    {
        header: "Tithe Farm",
        description: {
            about: "Optimally runs Tithe rather you need farming xp, collection logs, or AUTO WEEDER",
            feature: "- Automatically runs up to 20 Seeds Per Run\n- Automatically deposits fruits\n- Automatically withdraws seeds",
        },
    },
    {
        header: "Volcanic Mine",
        description: {
            about: "Need a dragon pickaxe but cba going to the Wildy or KQ? We've got you covered.",
            feature: "- Hits Optimal Point Threshold\n- Eats Food, Drinks Prayer Potions\n- Sips Stamina's to keep you sprinting.",
        },
    },
];

const freePluginDescriptions = [
    {
        header: "Alcher",
        description: {
            about: "Item stack too large? Wanna alch yourself? Let this plugin alch for you instead.",
            feature:
                "- Click Redirection (One-Click Mode)\n- Multi-item support\n- Alch States (Idle, While Moving, Anytime)\n- Stun-Tele-Alch",
        },
    },
    {
        header: "Auto Hop",
        description: {
            about: "Become a true introvert and instantly hop away from other players.",
            feature:
                "- Wilderness Toggle\n- Within Combat Range\n- Only Skulled Players\n- Members/Free Worlds\n- Distance Checks\n- World Filter",
        },
    },
    {
        header: "Blast Furnace (Gold & Steel)",
        description: {
            about: "Blast Furnace plugin that grabs ores, dumps them, and returns with bars! Full automation and redirected one-click supported!",
            feature:
                "- Gold and Steel bars supported\n- Coal Bag support\n- Glove switching\n- Stamina Potion support\n- Click Redirection (One-Click Mode)",
        },
    },
    {
        header: "Chop Fletch",
        description: {
            about: "Item stack too large? Wanna alch yourself? Let this plugin alch for you instead.",
            feature:
                "- Click Redirection (One-Click Mode)\n- Multi-item support\n- Alch States (Idle, While Moving, Anytime)\n- Stun-Tele-Alch",
        },
    },
    {
        header: "Continue Clicker",
        description: {
            about: "Speeds through dialogue! Compatible with the Quest Helper.",
            feature:
                "- Skips through dialogue\n- Option to 'Continue Quest Helper' dialogue choices\n- Option to 'Continue Make' default options via the space bar",
        },
    },
    {
        header: "Fungus Collector",
        description: {
            about: "A way to get infinite shrooms.",
            feature:
                "- Collects fungus using fairy ring BKR travel method\n- Sends fungus to bank via Butler\n- Auto or One Click modes supported",
        },
    },
    {
        header: "Karambwans",
        description: {
            about: "You get a karambwan, and you get a karambwan, everyone gets karambwans.",
            feature:
                "- Requirements:\n- Preset the fairy ring to Musa Point (DKP)\n- Karambwan Vessel\n- Karambwanji\n- Dramen staff (if you haven't done the diary)\n- Fish Barrel (optional)",
        },
    },
    {
        header: "Neverlog",
        description: {
            about: "Bypasses the logout timer until you hit 6 hours.",
            feature: "- More gameplay",
        },
    },
    {
        header: "Plank Maker",
        description: {
            about: "Makes planks via Camelot bank chest + PoH Butler",
            feature:
                "- Requirements:\n- Do one manual run by using your log on the servant and selecting take to sawmill.\n- GP in bank\n- Logs in bank\n- Start near Camelot PVP bank chest",
        },
    },
    {
        header: "Run Handler",
        description: {
            about: "It handles your run, what more can I say.",
            feature: "- Custom Min and Max run range settings\n- Drinks Stamina potions within a custom range\n- Runs Fast",
        },
    },
    {
        header: "Sandstone",
        description: {
            about: "Crafting grind seeming a little daunting? Start near the sandstone mine, kick back and relax.",
            feature: "- Gathers Sandstone and deposits into the grinder\n- Casts Humidify\n- One Click or Automated mode",
        },
    },
];

const pvmPluginDescriptions = [
    {
        header: "Auto Slayer",
        description: {
            about: "A fully automated slayer plugin!",
            feature:
                "- Supports solo Turael, Nieve & Duradel, as well as point boosting\n- Customise inventory/equipment/pouch/defensive prayer/offensive prayer depending on task\n- Customisable loot/alch list\n- Healing and prayer restoration via bank, house pool or ferox\n- Prayer flicking support\n- Supports superiors\n- Automatically hops to find a free world\n- Automated swapping of spellbooks depending on task (standard, lunar & ancient)\n- Automated weapon swapping and specing\n- Automated stacking and bursting\n- Automated saturated heart, potting and eating via autoeater\n- Automated alch/burying (with anti-alch list)\n- Automated paths between altars/bank/creatures (and uses items/POH when appropriate)\n- Automated blocks/skips tasks\n- Automated placement & reloading of cannon\n- Automated use of task specific items such as rock hammer & fungicide spray",
        },
    },
    {
        header: "Auto Bandos",
        description: {
            about: "A fully automated Bandos GWD killer! 3-5mil GP/hr based off of 25~ kills per hour!",
            feature:
                "- 9:0 Bowfa Method w/ Cannon Base\n- Restocking at Edgeville Bank\n- Gear swaps for getting kill count (Protection Items)\n- Gear swaps for killing minions (Minion Items)\n- Gear swaps for bursting (Burst Items + Enable bursting)\n- Supports Bones to Peaches\n- Supports Instances\n- Player avoidance during KC\n- Auto Cannon Base retrieval from Nulodion on emergency escape\n- Uses Altar, Resets Cannon and Kills The Boss!",
        },
    },
    {
        header: "Auto Zamorak",
        description: {
            about: "A fully automated K'ril Tsutsaroth GWD killer! Up to 20-25 kills per hour!",
            feature:
                "- Door | Altar Bowfa Method\n- Restocking at Edgeville Bank\n- Gear swaps for getting kill count (Protection Items)\n- Gear swaps for killing minions (Minion Items)\n- Gear swaps for bursting (Burst Items + Enable bursting)\n- Supports Instances\n- Player avoidance during KC",
        },
    },
    {
        header: "Auto Saradomin",
        description: {
            about: "A fully automated Commander Zilyana GWD killer!",
            feature:
                "- Restocking at Edgeville Bank\nGear swaps for getting kill count (Protection Items)\n- Gear swaps for killing minions (Minion Items)\n- Gear swaps for bursting (Burst Items + Enable bursting)\n- Configurable loot settings\n- Supports Instances\n- Player avoidance during KC",
        },
    },
    {
        header: "Auto Armadyl",
        description: {
            about: "A fully automated Kree'arra GWD killer!",
            feature:
                "- Chinning Support\n- Brews as food Support\n- Ecunemical Key Support\n- Gets KC via Aviansies if no keys are available\n- Restocking at Edgeville Bank\n- Gear swaps for getting kill count (Protection Items)\n- Gear swaps for killing minions (Minion Items)\n- Gear swaps for bursting (Burst Items + Enable bursting)\n- Supports Bones to Peaches\n- Supports Instances\n- Player avoidance during KC",
        },
    },
    {
        header: "Auto Zulrah",
        description: {
            about: "A fully automated Zulrah killer! Up to 30 kills per hour!",
            feature:
                "- Dynamically detects rotations\n- Custom swaps (mage/range)\n- Recoils/Suffering support\n- Thrall support\n- Restocking at Edgeville or Ferox Bank\n- Scroll, Fairy Ring & Charter Ship travel\n- Tick Eating",
        },
    },
    {
        header: "Auto Lizardmen",
        description: {
            about: "Make your DWH grind painless with this fully automated Lizardmen Shaman killer!",
            feature:
                "- Kills Lizardmen Shamans in Lizardmen Temple\n- Auto restocks at the farming guild\n- Alch support\n- Hop worlds to find free rooms",
        },
    },
];

export default function PluginList() {
    return (
        <div className="listContainer">
            <div className="PluginList">
                <header className="ListTitle">premium</header>
                <Accordion>
                    {pluginDescriptions.map((plugin, index) => {
                        return (
                            <Accordion.Item key={index} eventKey={plugin.header}>
                                <Accordion.Header>{plugin.header}</Accordion.Header>
                                <Accordion.Body className="accordionDescription">
                                    <h5>About:</h5>
                                    {plugin.description.about}
                                    <h4></h4>
                                    <h5>Features:</h5>
                                    {plugin.description.feature}
                                </Accordion.Body>
                            </Accordion.Item>
                        );
                    })}
                </Accordion>
            </div>
            <div className="PluginList">
                <header className="ListTitle">pvm</header>
                <Accordion>
                    {pvmPluginDescriptions.map((plugin, index) => {
                        return (
                            <Accordion.Item key={index} eventKey={plugin.header}>
                                <Accordion.Header>{plugin.header}</Accordion.Header>
                                <Accordion.Body className="accordionDescription">
                                    <h5>About:</h5>
                                    {plugin.description.about}
                                    <h4></h4>
                                    <h5>Features:</h5>
                                    {plugin.description.feature}
                                </Accordion.Body>
                            </Accordion.Item>
                        );
                    })}
                </Accordion>
            </div>
            <div className="PluginList">
                <header className="ListTitle">free</header>
                <Accordion>
                    {freePluginDescriptions.map((plugin, index) => {
                        return (
                            <Accordion.Item key={index} eventKey={plugin.header}>
                                <Accordion.Header>{plugin.header}</Accordion.Header>
                                <Accordion.Body className="accordionDescription">
                                    <h5>About:</h5>
                                    {plugin.description.about}
                                    <h4></h4>
                                    <h5>Features:</h5>
                                    {plugin.description.feature}
                                </Accordion.Body>
                            </Accordion.Item>
                        );
                    })}
                </Accordion>
            </div>
        </div>
    );
}
