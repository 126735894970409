import React, { useState } from "react";

import "../FooterStyle.css";
import ModalWindow from "../../Modal/Components/ModalWindow";
import { Changelog } from "../../Changelog/ChangeLog";

function getCurrentYear() {
    const date = new Date();
    return date.getFullYear();
}

export default function PageFooter() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const handleClick = () => {
        setShow(true);
    };
    return (
        <>
            <ModalWindow title="Changelog History" size="lg" show={show} onClose={handleClose}>
                <Changelog />
            </ModalWindow>
            <div className="footer">
                <div className="footer-container">
                    <div className="footer-copyright-container">
                        <p>&copy; {getCurrentYear()} Shismo Plugins </p>
                    </div>
                    <div className="footer-changelog-container">
                        <p onClick={() => handleClick()}>Changelog</p>
                    </div>
                </div>
            </div>
        </>
    );
}
