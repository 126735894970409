import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { update } from "../../services/AccessCode/authSlice";

const AuthCallback: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const getCode = async () => {
            const authCode: string = new URLSearchParams(location.search).get("code") ?? "";
            if (authCode) {
                dispatch(update(authCode));
                console.info("updating auth code", authCode);
                navigate("/");
            } else {
                console.error("No authorization code found");
            }
        };

        getCode();
    }, [location.search]);

    return <div>Logging in...</div>;
};

export default AuthCallback;
