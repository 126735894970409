import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { AccessToken, DiscordInformation, LicenseInfo, ProductResponse, StripeRequest } from "./query.interface";
import { REDIRECT_URI } from "../resources/globals";

export const shismoApi = createApi({
    reducerPath: "shismoApi",
    baseQuery: fetchBaseQuery({ baseUrl: "https://api.dev.shismoplugins.com/" }),
    tagTypes: ["Data"],
    endpoints: (builder) => ({
        GetStripeLink: builder.query<string, StripeRequest>({
            query: (input: StripeRequest) => ({
                method: "POST",
                url: "generateLink",
                body: input,
            }),
            providesTags: (result) => (result ? ["Data"] : ["Data"]),
        }),
        GetUserInformation: builder.query<AccessToken, string>({
            query: (token: string) => ({
                method: "POST",
                url: "website",
                body: {
                    code: token,
                    redirectUri: REDIRECT_URI,
                },
            }),
            providesTags: (result) => (result ? ["Data"] : ["Data"]),
        }),
        GetLicenseInformation: builder.query<LicenseInfo, AccessToken>({
            query: (token: AccessToken) => ({
                method: "POST",
                url: "userInfo",
                body: token,
            }),
            providesTags: (result) => (result ? ["Data"] : ["Data"]),
        }),
        GetProductInformation: builder.query<ProductResponse, string>({
            query: (token: string) => ({
                method: "POST",
                url: "productInfo",
                body: { data: token },
            }),
            providesTags: (result) => (result ? ["Data"] : ["Data"]),
        }),
        GetDiscordInformation: builder.query<DiscordInformation, AccessToken>({
            query: (token: AccessToken) => ({
                method: "POST",
                url: "discordInfo",
                body: token,
            }),
            providesTags: (result) => (result ? ["Data"] : ["Data"]),
        }),
    }),
});

// Export hooks for usage in functional components
export const {
    useGetStripeLinkQuery,
    useGetUserInformationQuery,
    useGetLicenseInformationQuery,
    useGetProductInformationQuery,
    useGetDiscordInformationQuery,
} = shismoApi;
