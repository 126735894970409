import { Draft, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AuthState } from "./auth.interface";

const initialState: AuthState = { accessCode: "" };

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        update: (state: Draft<AuthState>, action: PayloadAction<string>) => {
            state.accessCode = action.payload;
        },
    },
});

export const { update } = authSlice.actions;
export default authSlice.reducer;
