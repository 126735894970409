import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import React, { useState } from "react";

import Navbar from "react-bootstrap/Navbar";
import DiscordButton from "../DiscordButton/Component/DiscordButton";
// import NavDropdown from "react-bootstrap/NavDropdown";
import "./ShismoNavBar.css";
import premium from "../../resources/Premium.png";
import Button from "react-bootstrap/Button";
import ModalWindow from "../Modal/Components/ModalWindow";
import { LicenseKey } from "../Licensekey/LicenseKey";
import { useAppSelector } from "../../app/hooks";
import { selectToken } from "../../services/Token/tokenSelector";
import { useGetDiscordInformationQuery } from "../../services/shismoData";
import { ButtonToolbar } from "react-bootstrap";
// import discord from "../../resources/discord.png";

export default function ShismoNavBar() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const token: string = useAppSelector(selectToken);
    console.info("navbar token is: ", token);
    const pollingInterval = 0;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data, error, isLoading } = useGetDiscordInformationQuery({ token: token }, { pollingInterval });
    const shismoNavbar = data ? "shismo-navbar-logged-in" : "shismo-navbar-logged-out";
    const navBarImg = data ? "d-inline-block align-top navbar-img-logged-in" : "d-inline-block align-top navbar-img-logged-out";
    const handleClick = () => {
        setShow(true);
    };

    const handleLoginClick = () => {
        window.location.href =
            // "https://discord.com/api/oauth2/authorize?client_id=1053670992343482458&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fauth%2Fcallback&scope=identify+guilds+email";
            "https://discord.com/api/oauth2/authorize?client_id=1053670992343482458&response_type=code&redirect_uri=http%3A%2F%2Fshismoplugins.com%2Fauth%2Fcallback&scope=identify+guilds+email";
    };

    const handleLogoutClick = () => {
        sessionStorage.clear();
        window.location.reload();
    };

    return (
        <>
            <ModalWindow title="License Information" size="lg" show={show} onClose={handleClose}>
                <LicenseKey />
            </ModalWindow>

            <Navbar expand="lg" className={shismoNavbar}>
                <Container className="navbar-container">
                    <Navbar.Brand href="#home">
                        <img alt="" src={premium} className={navBarImg} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav>
                            {token ? (
                                <>
                                    {data ? (
                                        <div className="welcomeContainer">
                                            <header className="WelcomeText">
                                                {`username: ${data.username !== null ? data.username.toLowerCase() : "null"}`}
                                                <br />
                                                {`email: ${data.email !== null ? data.email.toLowerCase() : "null"}`}
                                            </header>
                                        </div>
                                    ) : null}
                                    <div className="discordContainer">
                                        <ButtonToolbar className="buttonToolbar">
                                            <Button className="navButtons" type="submit" onClick={() => handleClick()}>
                                                license key
                                            </Button>
                                            <Button className="navButtons" type="submit" onClick={handleLogoutClick}>
                                                log out
                                            </Button>
                                        </ButtonToolbar>
                                    </div>
                                </>
                            ) : (
                                <div className="discordContainer">
                                    <DiscordButton onClick={handleLoginClick} label="login with discord" />
                                </div>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}
