import convertUnixTimeToDate from "./ConvertUnixTimeToDate";

// days hours mins
const convertUnixTimeToRemaining = (unixTime: number | string | null): string => {
    if (unixTime === null || unixTime === "-1") {
        return "-1";
    }

    const currentDate = new Date();
    const endTime = convertUnixTimeToDate(parseInt(unixTime.toString(), 10));
    let timeDiff = endTime.getTime() - currentDate.getTime();

    const days_diff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    timeDiff -= days_diff * (1000 * 60 * 60 * 24);
    const hours_diff = Math.floor(timeDiff / (1000 * 60 * 60));

    timeDiff -= hours_diff * (1000 * 60 * 60);
    const mins_diff = Math.floor(timeDiff / (1000 * 60));

    timeDiff -= mins_diff * (1000 * 60);
    const seconds_diff = Math.floor(timeDiff / 1000);

    // Format the result as a string
    const result = `${days_diff} days, ${hours_diff} hours, ${mins_diff} minutes, ${seconds_diff} seconds`;
    return result;
};

export default convertUnixTimeToRemaining;
