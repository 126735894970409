import React from "react";
import "./PurchaseTile.css";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useGetStripeLinkQuery } from "../../services/shismoData";
import { StripeRequest } from "../../services/query.interface";
import { Spinner } from "react-bootstrap";
import { BASE_URI } from "../../resources/globals";

function determineCurrency(price: string) {
    if (price.includes("$")) {
        return "usd";
    }
    return "eur";
}

export default function PurchaseTile({
    token,
    product,
    days,
    instances,
    price,
    image,
}: {
    token: string;
    product: string;
    days: number;
    instances: number;
    price: string;
    image: string;
}) {
    // const link = "www.google.co.uk";
    const handleClick = () => {
        window.location.replace(data ?? "https://shismoplugins.com");
    };
    const currency = determineCurrency(price);
    const inputData: StripeRequest = {
        product: product,
        currency: currency,
        days: days,
        redirectUri: BASE_URI,
        token: token,
        instances: instances,
    };
    const pollingInterval = 0;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data, error, isLoading } = useGetStripeLinkQuery(inputData, {
        pollingInterval,
    });

    const productDescription = `${days} Days - 3 Devices`;
    return (
        <Card className="text-center" style={{ width: "18rem" }}>
            <Card.Img variant="top" src={image} />
            <Card.Body>
                {/* <Card.Title className="cardTitle">Shismo {product}</Card.Title> */}
                <Card.Text className="cardText">{productDescription}</Card.Text>
                {product !== "Premium" ? <Card.Text className="cardText">{`${instances} Instances`}</Card.Text> : null}
                <Card.Text className="cardPrice">{price}</Card.Text>
                <>
                    {isLoading ? (
                        <Button style={{ justifyContent: "center" }} variant="primary" disabled>
                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                            Loading...
                        </Button>
                    ) : error ? (
                        <Button style={{ justifyContent: "center" }} variant="primary" disabled>
                            ERROR
                        </Button>
                    ) : data ? (
                        <Button
                            className="purchaseButton"
                            style={{ justifyContent: "center" }}
                            variant="primary"
                            onClick={() => handleClick()}
                        >
                            Purchase
                        </Button>
                    ) : null}
                </>
            </Card.Body>
        </Card>
    );
}
