import { Draft, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AccessToken } from "../query.interface";

const initialState: AccessToken = { token: "" };

const tokenSlice = createSlice({
    name: "token",
    initialState,
    reducers: {
        update: (state: Draft<AccessToken>, action: PayloadAction<string>) => {
            state.token = action.payload;
        },
    },
});

export const { update } = tokenSlice.actions;
export default tokenSlice.reducer;
