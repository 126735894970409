import React, { useEffect, useState } from "react";
import "./ProductWindow.css";
import { Button, Row, Tab, Col, Nav, ButtonToolbar } from "react-bootstrap";
import PurchaseTile from "../PurchaseTile/PurchaseTile";

import premium from "../../resources/Premium.png";
import zulrah from "../../resources/Zulrah.png";
import bandos from "../../resources/Bandos.png";
import zamorak from "../../resources/Zamorak.png";
import armadyl from "../../resources/Armadyl.png";
import saradomin from "../../resources/Sara.png";
import slayer from "../../resources/Slayer.png";
import lizardmen from "../../resources/Lizardmen.png";
import vorkath from "../../resources/Vorkath.png";
import { useGetProductInformationQuery, useGetUserInformationQuery } from "../../services/shismoData";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectCode } from "../../services/AccessCode/authSelector";
import { update } from "../../services/Token/tokenSlice";
import PluginList from "../PluginList/PluginList";
import LoggedOutDisplay from "../LoggedOutWindow/LoggedOutWindow";

export default function ProductWindow() {
    const dispatch = useAppDispatch();
    const [currency, setCurrency] = useState("USD");
    const accessCode: string = useAppSelector(selectCode);
    console.info("access code is: ", accessCode);
    const pollingInterval = 0;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data, error, isLoading, isFetching } = useGetUserInformationQuery(accessCode, { pollingInterval });

    const { data: productData } = useGetProductInformationQuery("blank", { pollingInterval });
    const productWindow = data ? "product-window-logged-in" : "product-window-logged-out";
    useEffect(() => {
        if (data) {
            console.info("Data updated: ", data);
            dispatch(update(data.token));
        }
    }, [data]);

    return (
        <>
            {isLoading ? (
                <div className={productWindow}>
                    <>LOADING</>
                </div>
            ) : error ? (
                <div className={productWindow}>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="Plugin List">
                        <Row className="remove-margin-row">
                            <Nav variant="tabs" className="nav-flex-container" defaultActiveKey="Plugins">
                                {[
                                    "Plugins",
                                    "Showcase",
                                    // "Premium",
                                    // "Slayer",
                                    // "Bandos",
                                    // "Armadyl",
                                    // "Zamorak",
                                    // "Saradomin",
                                    // "Zulrah",
                                    // "Lizardmen",
                                ].map((eventKey) => (
                                    <Nav.Item key={eventKey} className="nav-flex-item">
                                        <Nav.Link eventKey={eventKey}>{eventKey.toLowerCase()}</Nav.Link>
                                    </Nav.Item>
                                ))}
                            </Nav>
                        </Row>
                        <Col style={{ width: "100% !important" }}>
                            <Tab.Content>
                                <Tab.Pane eventKey="Plugins">
                                    <PluginList />
                                </Tab.Pane>
                                <Tab.Pane eventKey="Showcase">
                                    <>UNDER CONSTRUCTION</>
                                    {/* <Row className="remove-margin-row">
                                        <ButtonToolbar className="currency-selector">
                                            <Button
                                                {...{ className: currency === "USD" ? "currency-selected" : "currency-button" }}
                                                onClick={() => setCurrency("USD")}
                                                variant="$"
                                            >
                                                $ usd
                                            </Button>
                                            <Button
                                                {...{ className: currency === "EURO" ? "currency-selected" : "currency-button" }}
                                                onClick={() => setCurrency("EURO")}
                                                variant="€"
                                            >
                                                € euro
                                            </Button>
                                        </ButtonToolbar>
                                    </Row> */}
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Tab.Container>
                </div>
            ) : data ? (
                <div className={productWindow}>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="Plugin List">
                        <Row className="remove-margin-row">
                            <Nav variant="tabs" className="nav-flex-container" defaultActiveKey="Plugins">
                                {[
                                    "Plugins",
                                    "Showcase",
                                    "Premium",
                                    "Slayer",
                                    "Bandos",
                                    "Armadyl",
                                    "Zamorak",
                                    "Saradomin",
                                    "Zulrah",
                                    "Lizardmen",
                                    "Vorkath",
                                ].map((eventKey) => (
                                    <Nav.Item key={eventKey} className="nav-flex-item">
                                        <Nav.Link eventKey={eventKey}>{eventKey.toLowerCase()}</Nav.Link>
                                    </Nav.Item>
                                ))}
                            </Nav>
                        </Row>
                        <Col style={{ width: "100% !important" }}>
                            <Tab.Content>
                                <Tab.Pane eventKey="Plugins">
                                    <PluginList />
                                </Tab.Pane>

                                <Tab.Pane eventKey="Premium">
                                    <ButtonToolbar className="currency-selector">
                                        <Button
                                            {...{ className: currency === "USD" ? "currency-selected" : "currency-button" }}
                                            onClick={() => setCurrency("USD")}
                                            variant="$"
                                        >
                                            $ usd
                                        </Button>
                                        <Button
                                            {...{ className: currency === "EURO" ? "currency-selected" : "currency-button" }}
                                            onClick={() => setCurrency("EURO")}
                                            variant="€"
                                        >
                                            € euro
                                        </Button>
                                    </ButtonToolbar>
                                    <Row xs={1} md={2} className="g-4">
                                        {productData ? (
                                            <>
                                                {currency === "USD"
                                                    ? productData.usd.map((product, index) => {
                                                          if (product.product === "Premium")
                                                              return (
                                                                  <PurchaseTile
                                                                      key={index}
                                                                      price={`$${Number(product.price / 100).toFixed(2)}`}
                                                                      token={data.token}
                                                                      days={product.days ?? 30}
                                                                      product={product.product}
                                                                      image={premium}
                                                                      instances={product.instances ?? 2}
                                                                  />
                                                              );
                                                      })
                                                    : productData.euro.map((product, index) => {
                                                          if (product.product === "Premium")
                                                              return (
                                                                  <PurchaseTile
                                                                      key={index}
                                                                      price={`€${Number(product.price / 100).toFixed(2)}`}
                                                                      token={data.token}
                                                                      days={product.days ?? 30}
                                                                      product={product.product}
                                                                      image={premium}
                                                                      instances={product.instances ?? 2}
                                                                  />
                                                              );
                                                      })}
                                            </>
                                        ) : null}
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="Slayer">
                                    <ButtonToolbar className="currency-selector">
                                        <Button
                                            {...{ className: currency === "USD" ? "currency-selected" : "currency-button" }}
                                            onClick={() => setCurrency("USD")}
                                            variant="$"
                                        >
                                            $ usd
                                        </Button>
                                        <Button
                                            {...{ className: currency === "EURO" ? "currency-selected" : "currency-button" }}
                                            onClick={() => setCurrency("EURO")}
                                            variant="€"
                                        >
                                            € euro
                                        </Button>
                                    </ButtonToolbar>
                                    <Row xs={1} md={2} className="g-4">
                                        {productData ? (
                                            <>
                                                {currency === "USD"
                                                    ? productData.usd.map((product, index) => {
                                                          if (product.product === "Slayer")
                                                              return (
                                                                  <PurchaseTile
                                                                      key={index}
                                                                      price={`$${Number(product.price / 100).toFixed(2)}`}
                                                                      token={data.token}
                                                                      days={product.days ?? 30}
                                                                      product={product.product}
                                                                      image={slayer}
                                                                      instances={product.instances ?? 2}
                                                                  />
                                                              );
                                                      })
                                                    : productData.euro.map((product, index) => {
                                                          if (product.product === "Slayer")
                                                              return (
                                                                  <PurchaseTile
                                                                      key={index}
                                                                      price={`€${Number(product.price / 100).toFixed(2)}`}
                                                                      token={data.token}
                                                                      days={product.days ?? 30}
                                                                      product={product.product}
                                                                      image={slayer}
                                                                      instances={product.instances ?? 2}
                                                                  />
                                                              );
                                                      })}
                                            </>
                                        ) : null}
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="Zulrah">
                                    <ButtonToolbar className="currency-selector">
                                        <Button
                                            {...{ className: currency === "USD" ? "currency-selected" : "currency-button" }}
                                            onClick={() => setCurrency("USD")}
                                            variant="$"
                                        >
                                            $ usd
                                        </Button>
                                        <Button
                                            {...{ className: currency === "EURO" ? "currency-selected" : "currency-button" }}
                                            onClick={() => setCurrency("EURO")}
                                            variant="€"
                                        >
                                            € euro
                                        </Button>
                                    </ButtonToolbar>
                                    <Row xs={1} md={2} className="g-4">
                                        {productData ? (
                                            <>
                                                {currency === "USD"
                                                    ? productData.usd.map((product, index) => {
                                                          if (product.product === "Zulrah")
                                                              return (
                                                                  <PurchaseTile
                                                                      key={index}
                                                                      price={`$${Number(product.price / 100).toFixed(2)}`}
                                                                      token={data.token}
                                                                      days={product.days ?? 30}
                                                                      product={product.product}
                                                                      image={zulrah}
                                                                      instances={product.instances ?? 2}
                                                                  />
                                                              );
                                                      })
                                                    : productData.euro.map((product, index) => {
                                                          if (product.product === "Zulrah")
                                                              return (
                                                                  <PurchaseTile
                                                                      key={index}
                                                                      price={`€${Number(product.price / 100).toFixed(2)}`}
                                                                      token={data.token}
                                                                      days={product.days ?? 30}
                                                                      product={product.product}
                                                                      image={zulrah}
                                                                      instances={product.instances ?? 2}
                                                                  />
                                                              );
                                                      })}
                                            </>
                                        ) : null}
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="Bandos">
                                    <ButtonToolbar className="currency-selector">
                                        <Button
                                            {...{ className: currency === "USD" ? "currency-selected" : "currency-button" }}
                                            onClick={() => setCurrency("USD")}
                                            variant="$"
                                        >
                                            $ usd
                                        </Button>
                                        <Button
                                            {...{ className: currency === "EURO" ? "currency-selected" : "currency-button" }}
                                            onClick={() => setCurrency("EURO")}
                                            variant="€"
                                        >
                                            € euro
                                        </Button>
                                    </ButtonToolbar>
                                    <Row xs={1} md={2} className="g-4">
                                        {productData ? (
                                            <>
                                                {currency === "USD"
                                                    ? productData.usd.map((product, index) => {
                                                          if (product.product === "Bandos")
                                                              return (
                                                                  <PurchaseTile
                                                                      key={index}
                                                                      price={`$${Number(product.price / 100).toFixed(2)}`}
                                                                      token={data.token}
                                                                      days={product.days ?? 30}
                                                                      product={product.product}
                                                                      image={bandos}
                                                                      instances={product.instances ?? 2}
                                                                  />
                                                              );
                                                      })
                                                    : productData.euro.map((product, index) => {
                                                          if (product.product === "Bandos")
                                                              return (
                                                                  <PurchaseTile
                                                                      key={index}
                                                                      price={`€${Number(product.price / 100).toFixed(2)}`}
                                                                      token={data.token}
                                                                      days={product.days ?? 30}
                                                                      product={product.product}
                                                                      image={bandos}
                                                                      instances={product.instances ?? 2}
                                                                  />
                                                              );
                                                      })}
                                            </>
                                        ) : null}
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="Armadyl">
                                    <ButtonToolbar className="currency-selector">
                                        <Button
                                            {...{ className: currency === "USD" ? "currency-selected" : "currency-button" }}
                                            onClick={() => setCurrency("USD")}
                                            variant="$"
                                        >
                                            $ usd
                                        </Button>
                                        <Button
                                            {...{ className: currency === "EURO" ? "currency-selected" : "currency-button" }}
                                            onClick={() => setCurrency("EURO")}
                                            variant="€"
                                        >
                                            € euro
                                        </Button>
                                    </ButtonToolbar>
                                    <Row xs={1} md={2} className="g-4">
                                        {productData ? (
                                            <>
                                                {currency === "USD"
                                                    ? productData.usd.map((product, index) => {
                                                          if (product.product === "Armadyl")
                                                              return (
                                                                  <PurchaseTile
                                                                      key={index}
                                                                      price={`$${Number(product.price / 100).toFixed(2)}`}
                                                                      token={data.token}
                                                                      days={product.days ?? 30}
                                                                      product={product.product}
                                                                      image={armadyl}
                                                                      instances={product.instances ?? 2}
                                                                  />
                                                              );
                                                      })
                                                    : productData.euro.map((product, index) => {
                                                          if (product.product === "Armadyl")
                                                              return (
                                                                  <PurchaseTile
                                                                      key={index}
                                                                      price={`€${Number(product.price / 100).toFixed(2)}`}
                                                                      token={data.token}
                                                                      days={product.days ?? 30}
                                                                      product={product.product}
                                                                      image={armadyl}
                                                                      instances={product.instances ?? 2}
                                                                  />
                                                              );
                                                      })}
                                            </>
                                        ) : null}
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="Zamorak">
                                    <ButtonToolbar className="currency-selector">
                                        <Button
                                            {...{ className: currency === "USD" ? "currency-selected" : "currency-button" }}
                                            onClick={() => setCurrency("USD")}
                                            variant="$"
                                        >
                                            $ usd
                                        </Button>
                                        <Button
                                            {...{ className: currency === "EURO" ? "currency-selected" : "currency-button" }}
                                            onClick={() => setCurrency("EURO")}
                                            variant="€"
                                        >
                                            € euro
                                        </Button>
                                    </ButtonToolbar>
                                    <Row xs={1} md={2} className="g-4">
                                        {productData ? (
                                            <>
                                                {currency === "USD"
                                                    ? productData.usd.map((product, index) => {
                                                          if (product.product === "Zamorak")
                                                              return (
                                                                  <PurchaseTile
                                                                      key={index}
                                                                      price={`$${Number(product.price / 100).toFixed(2)}`}
                                                                      token={data.token}
                                                                      days={product.days ?? 30}
                                                                      product={product.product}
                                                                      image={zamorak}
                                                                      instances={product.instances ?? 2}
                                                                  />
                                                              );
                                                      })
                                                    : productData.euro.map((product, index) => {
                                                          if (product.product === "Zamorak")
                                                              return (
                                                                  <PurchaseTile
                                                                      key={index}
                                                                      price={`€${Number(product.price / 100).toFixed(2)}`}
                                                                      token={data.token}
                                                                      days={product.days ?? 30}
                                                                      product={product.product}
                                                                      image={zamorak}
                                                                      instances={product.instances ?? 2}
                                                                  />
                                                              );
                                                      })}
                                            </>
                                        ) : null}
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="Saradomin">
                                    <ButtonToolbar className="currency-selector">
                                        <Button
                                            {...{ className: currency === "USD" ? "currency-selected" : "currency-button" }}
                                            onClick={() => setCurrency("USD")}
                                            variant="$"
                                        >
                                            $ usd
                                        </Button>
                                        <Button
                                            {...{ className: currency === "EURO" ? "currency-selected" : "currency-button" }}
                                            onClick={() => setCurrency("EURO")}
                                            variant="€"
                                        >
                                            € euro
                                        </Button>
                                    </ButtonToolbar>
                                    <Row xs={1} md={2} className="g-4">
                                        {productData ? (
                                            <>
                                                {currency === "USD"
                                                    ? productData.usd.map((product, index) => {
                                                          if (product.product === "Saradomin")
                                                              return (
                                                                  <PurchaseTile
                                                                      key={index}
                                                                      price={`$${Number(product.price / 100).toFixed(2)}`}
                                                                      token={data.token}
                                                                      days={product.days ?? 30}
                                                                      product={product.product}
                                                                      image={saradomin}
                                                                      instances={product.instances ?? 2}
                                                                  />
                                                              );
                                                      })
                                                    : productData.euro.map((product, index) => {
                                                          if (product.product === "Saradomin")
                                                              return (
                                                                  <PurchaseTile
                                                                      key={index}
                                                                      price={`€${Number(product.price / 100).toFixed(2)}`}
                                                                      token={data.token}
                                                                      days={product.days ?? 30}
                                                                      product={product.product}
                                                                      image={saradomin}
                                                                      instances={product.instances ?? 2}
                                                                  />
                                                              );
                                                      })}
                                            </>
                                        ) : null}
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="Lizardmen">
                                    <ButtonToolbar className="currency-selector">
                                        <Button
                                            {...{ className: currency === "USD" ? "currency-selected" : "currency-button" }}
                                            onClick={() => setCurrency("USD")}
                                            variant="$"
                                        >
                                            $ usd
                                        </Button>
                                        <Button
                                            {...{ className: currency === "EURO" ? "currency-selected" : "currency-button" }}
                                            onClick={() => setCurrency("EURO")}
                                            variant="€"
                                        >
                                            € euro
                                        </Button>
                                    </ButtonToolbar>
                                    <Row xs={1} md={2} className="g-4">
                                        {productData ? (
                                            <>
                                                {currency === "USD"
                                                    ? productData.usd.map((product, index) => {
                                                          if (product.product === "Lizardmen")
                                                              return (
                                                                  <PurchaseTile
                                                                      key={index}
                                                                      price={`$${Number(product.price / 100).toFixed(2)}`}
                                                                      token={data.token}
                                                                      days={product.days ?? 30}
                                                                      product={product.product}
                                                                      image={lizardmen}
                                                                      instances={product.instances ?? 2}
                                                                  />
                                                              );
                                                      })
                                                    : productData.euro.map((product, index) => {
                                                          if (product.product === "Lizardmen")
                                                              return (
                                                                  <PurchaseTile
                                                                      key={index}
                                                                      price={`€${Number(product.price / 100).toFixed(2)}`}
                                                                      token={data.token}
                                                                      days={product.days ?? 30}
                                                                      product={product.product}
                                                                      image={lizardmen}
                                                                      instances={product.instances ?? 2}
                                                                  />
                                                              );
                                                      })}
                                            </>
                                        ) : null}
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="Vorkath">
                                    <ButtonToolbar className="currency-selector">
                                        <Button
                                            {...{ className: currency === "USD" ? "currency-selected" : "currency-button" }}
                                            onClick={() => setCurrency("USD")}
                                            variant="$"
                                        >
                                            $ usd
                                        </Button>
                                        <Button
                                            {...{ className: currency === "EURO" ? "currency-selected" : "currency-button" }}
                                            onClick={() => setCurrency("EURO")}
                                            variant="€"
                                        >
                                            € euro
                                        </Button>
                                    </ButtonToolbar>
                                    <Row xs={1} md={2} className="g-4">
                                        {productData ? (
                                            <>
                                                {currency === "USD"
                                                    ? productData.usd.map((product, index) => {
                                                          if (product.product === "Vorkath")
                                                              return (
                                                                  <PurchaseTile
                                                                      key={index}
                                                                      price={`$${Number(product.price / 100).toFixed(2)}`}
                                                                      token={data.token}
                                                                      days={product.days ?? 30}
                                                                      product={product.product}
                                                                      image={vorkath}
                                                                      instances={product.instances ?? 2}
                                                                  />
                                                              );
                                                      })
                                                    : productData.euro.map((product, index) => {
                                                          if (product.product === "Vorkath")
                                                              return (
                                                                  <PurchaseTile
                                                                      key={index}
                                                                      price={`€${Number(product.price / 100).toFixed(2)}`}
                                                                      token={data.token}
                                                                      days={product.days ?? 30}
                                                                      product={product.product}
                                                                      image={vorkath}
                                                                      instances={product.instances ?? 2}
                                                                  />
                                                              );
                                                      })}
                                            </>
                                        ) : null}
                                    </Row>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Tab.Container>
                </div>
            ) : (
                <LoggedOutDisplay />
            )}
        </>
    );
}
