import React from "react";
import { RouteObject } from "react-router-dom";
import ShismoNavBar from "./features/ShismoNavBar/ShismoNavBar";
import ProductWindow from "./features/ProductWindow/ProductWindow";
// import AuthCallback from "./features/Authentication/AuthCallback";
import PageFooter from "./features/Footer/Components/PageFooter";
import AuthCallback from "./features/Authentication/AuthCallback";

//defines all the routes on the webpage. First element in array is default. <Home /> (you could tecnically write <Home></Home> but <Home /> is shorthand with no nest component.)
const routes: RouteObject[] = [
    {
        path: "/",
        element: (
            <>
                {/* <AuthCallback /> */}
                <ShismoNavBar />
                <ProductWindow />
                <PageFooter />
            </>
        ),
    },
    {
        path: "/auth/callback",
        element: (
            <>
                <AuthCallback />
            </>
        ),
    },
];

export { routes };
