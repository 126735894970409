import React, { useState } from "react";
import convertUnixTimeToRemaining from "../../services/ConvertUnixTimeRemaining";
import { selectToken } from "../../services/Token/tokenSelector";
import { useAppSelector } from "../../app/hooks";
import { useGetLicenseInformationQuery } from "../../services/shismoData";

export const LicenseKey = () => {
    const [isHovered, setIsHovered] = useState(false);
    const token: string = useAppSelector(selectToken);
    console.info("token is: ", token);
    const pollingInterval = 0;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data, error, isLoading, isFetching } = useGetLicenseInformationQuery({ token: token }, { pollingInterval });

    const copyToClipboard = () => {
        navigator.clipboard.writeText(data?.licenseKey || "");
    };

    return (
        <>
            {isLoading ? (
                <>Loading Data</>
            ) : error ? (
                <>Error Loading Data</>
            ) : data ? (
                <>
                    {console.info(data)}
                    <div
                        className={`license-key-container ${isHovered ? "hovered" : ""} `}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        {"License key: "}
                        {isHovered ? data.licenseKey : "Hover to reveal"}
                    </div>
                    <button onClick={copyToClipboard}>Copy</button>
                    <br />
                    {data.hwidList ? (
                        <>
                            <br />
                            {data.hwidList.map((expiry) => {
                                if (expiry.expiryTime !== undefined) {
                                    return (
                                        <>
                                            <>HWID Remaining Time: {convertUnixTimeToRemaining(expiry.expiryTime)}</>
                                            <br />
                                        </>
                                    );
                                }
                            })}
                        </>
                    ) : null}
                    {data.premiumExpiry ? (
                        <>
                            <br />
                            Premium remaining time: {convertUnixTimeToRemaining(data.premiumExpiry)}
                            <br />
                        </>
                    ) : null}
                    {data.slayerExpiries ? (
                        <>
                            <br />
                            {data.slayerExpiries.map((expiry) => {
                                if (expiry.expiryTime !== undefined) {
                                    return (
                                        <>
                                            <>Slayer remaining time: {convertUnixTimeToRemaining(expiry.expiryTime)}</>
                                            <br />
                                        </>
                                    );
                                }
                            })}
                        </>
                    ) : null}
                    {data.zulrahExpiries ? (
                        <>
                            <br />
                            {data.zulrahExpiries.map((expiry) => {
                                if (expiry.expiryTime !== undefined) {
                                    return (
                                        <>
                                            <>Zulrah remaining time: {convertUnixTimeToRemaining(expiry.expiryTime)}</>
                                            <br />
                                        </>
                                    );
                                }
                            })}
                        </>
                    ) : null}
                    {data.lizardExpiries ? (
                        <>
                            <br />
                            {data.lizardExpiries.map((expiry) => {
                                if (expiry.expiryTime !== undefined) {
                                    return (
                                        <>
                                            <>Lizard remaining time: {convertUnixTimeToRemaining(expiry.expiryTime)}</>
                                            <br />
                                        </>
                                    );
                                }
                            })}
                        </>
                    ) : null}
                    {data.bandosExpiries ? (
                        <>
                            <br />
                            {data.bandosExpiries.map((expiry) => {
                                if (expiry.expiryTime !== undefined) {
                                    return (
                                        <>
                                            <>Bandos remaining time: {convertUnixTimeToRemaining(expiry.expiryTime)}</>
                                            <br />
                                        </>
                                    );
                                }
                            })}
                        </>
                    ) : null}
                    {data.zamorakExpiries ? (
                        <>
                            <br />
                            {data.zamorakExpiries.map((expiry) => {
                                if (expiry.expiryTime !== undefined) {
                                    return (
                                        <>
                                            <>Zamorak remaining time: {convertUnixTimeToRemaining(expiry.expiryTime)}</>
                                            <br />
                                        </>
                                    );
                                }
                            })}
                        </>
                    ) : null}
                    {data.armadylExpiries ? (
                        <>
                            <br />
                            {data.armadylExpiries.map((expiry) => {
                                if (expiry.expiryTime !== undefined) {
                                    return (
                                        <>
                                            <>Armadyl remaining time: {convertUnixTimeToRemaining(expiry.expiryTime)}</>
                                            <br />
                                        </>
                                    );
                                }
                            })}
                        </>
                    ) : null}
                    {data.saradominExpiries ? (
                        <>
                            <br />
                            {data.saradominExpiries.map((expiry) => {
                                if (expiry.expiryTime !== undefined) {
                                    return (
                                        <>
                                            <>Saradomin remaining time: {convertUnixTimeToRemaining(expiry.expiryTime)}</>
                                            <br />
                                        </>
                                    );
                                }
                            })}
                        </>
                    ) : null}
                    {data.vorkathExpiries ? (
                        <>
                            <br />
                            {data.vorkathExpiries.map((expiry) => {
                                if (expiry.expiryTime !== undefined) {
                                    return (
                                        <>
                                            <>Vorkath remaining time: {convertUnixTimeToRemaining(expiry.expiryTime)}</>
                                            <br />
                                        </>
                                    );
                                }
                            })}
                        </>
                    ) : null}
                </>
            ) : null}
        </>
    );
};
