import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { shismoApi } from "../services/shismoData";
import authSlice from "../services/AccessCode/authSlice";
import tokenSlice from "../services/Token/tokenSlice";

export const store = configureStore({
    reducer: {
        token: tokenSlice,
        auth: authSlice,
        [shismoApi.reducerPath]: shismoApi.reducer,
    },
    middleware: (gDM) => gDM().concat(shismoApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
