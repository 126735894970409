import React from "react";
import "./App.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { routes } from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";

const router = createBrowserRouter(routes);

function App() {
    //this is loading the config for the oauth when the webapp first loads.
    return <RouterProvider router={router} />;
}

export default App;
